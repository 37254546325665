import regions from '../data/regions.json'

export function CheckValidRegion(sendbirdRegion: string) {
    let contains = false;
    regions.forEach((a) => {
        if (a.value === sendbirdRegion) {
            contains = true;
        }
    });
    return contains;
}

export function DefaultPath(sendbirdRegion: string) {
    return RegionPath(sendbirdRegion, 'index');
}

export function RegionPath(sendbirdRegion: string, path: string) {
    return `/regions/${sendbirdRegion}/${path}`;
}

export function RegionNormalizedPath(sendbirdRegion: string, path: string) {
    return `/regions/:id/${path}`;
}
