import React from "react";
import RequestListTable from "../../../components/ScheduledAction/RequestListTable";
import {useParams} from "react-router-dom";

export default function RequestList() {
    const {id}: { id: string } = useParams()
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <RequestListTable
                        sendbirdRegion={id}
                        title={"Scheduled Action Request List"}
                    />
                </div>
            </div>
        </>
    );
}
