import React from "react";
import RequestFormTemplate from "../../../components/ScheduledAction/RequestFormTemplate";
import {useParams} from "react-router-dom";

export default function RequestForm() {
    const {id}: { id: string } = useParams()
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full lg:w-8/12 px-4">
                    <RequestFormTemplate
                        sendbirdRegion={id}
                    />
                </div>
            </div>
        </>
    );
}
