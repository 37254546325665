import React, {useEffect} from "react";
import {Switch, Route, Redirect, useParams} from "react-router-dom";

// components

// views
import Sidebar from "../components/Sidebar/Sidebar";
import {CheckValidRegion, RegionNormalizedPath, RegionPath} from "../utils/utils";
import {GenerateUrls} from "./Urls";

export default function Admin() {
    const {id}: { id: string } = useParams()

    useEffect(()=>{
        //TODO: We should find a better way to prevent the wrong region value.
        if (!CheckValidRegion(id)) {
            alert("choosing invalid region");
            window.location.href = '/regions/us-1/index';
        }
    }, [])

    const urls = GenerateUrls();
    return (
        <>
            <Sidebar
                sendbirdRegion={id}
            />
            <div className="relative md:ml-64 bg-white-100">
                {/* Header */}

                <div className="px-4 md:px-10 mx-auto w-full relative bg-white md:pt-32 pb-32 pt-12">
                    <Switch>
                        {urls.map(url => {
                           return <Route key={url.path} path={RegionNormalizedPath(id, url.path)} component={url.component} />
                        })}
                        <Redirect from="/" to={RegionPath(id, "index")}/>
                    </Switch>
                </div>
            </div>
        </>
    );
}
