import RequestForm from "../views/admin/scheduledAction/RequestForm";
import Index from "../views/admin/Index";
import RequestList from "../views/admin/scheduledAction/RequestList";
import History from "../views/admin/scheduledAction/History";

const urls = [
    { "path": 'index', "component": Index },
    { "path": 'scheduled-action/request', "component": RequestForm },
    { "path": 'scheduled-action/list', "component": RequestList },
    { "path": 'scheduled-action/history', "component": History }
]

export function GenerateUrls() {
    return urls;
}
