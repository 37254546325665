import React from "react";

// components

export default function Index() {
  return (
    <div className="px-4 md:px-10 mx-auto w-full relative bg-white-600 md:pt-32 pb-32 pt-12">
      <div className="flex flex-wrap">
        <div className="w-full px-4">
            <h1 className="text-3xl font-normal leading-normal mt-0 mb-2 text-slate-800">
                Welcome to Phoenix
            </h1>
        </div>
          <hr className="w-full"/>
          <div className="w-full lg:w-6/12 px-4 pt-8">
              <pre className="text-md font-normal leading-normal mt-0 mb-2 text-slate-800">
                Please Read following directions if you are new to Phoenix
              </pre>
          </div>
      </div>
    </div>
  );
}
