import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Admin from "./layouts/Admin";
import Auth from "./layouts/Auth";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
      <Switch>
          {/* add routes with layouts */}
          <Route path="/auth" component={Auth} />
          <Route path="/regions/:id/" component={Admin} />
          {/* add redirect for first page */}
          <Redirect from="*" to="/regions/us-1/index" />
      </Switch>
  </BrowserRouter>,
);
