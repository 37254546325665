/*eslint-disable*/
import React, {useState} from "react";
import regions from "../../data/regions.json"
import {DefaultPath} from "../../utils/utils";

export default function RegionSelection({ sendbirdRegion }: {sendbirdRegion: String}) {
    const [selectedOption, setSelectedOption] = useState<String>(sendbirdRegion);

    // This function is triggered when the select changes
    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        window.location.href = DefaultPath(value);
    };

    return (
        <>
        <div className="w-12/12 text-center">
            <select id="underline_select"
                    className="md:block w-full text-center md:pb-2 text-slate-600 inline-block whitespace-nowrap text-md font-bold p-4 px-0"
                    onChange={selectChange}
                    defaultValue={selectedOption.toString()}
            >
                {regions.map((option) =>
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                )}
            </select>
        </div>
        </>
    )
}
