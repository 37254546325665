import React from "react";
import PropTypes from "prop-types";

// components
function buildData() {
  return [["1", "no1", "Daniel Lee", "Pending"]]
}

export default function RequestListTable({ color, title, sendbirdRegion }: {color: string, title: string, sendbirdRegion: string}) {
  const headers = ["Request ID", "Region" , "Owner", "Status", "Confirm"]
  const dataList = buildData()
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-slate-700" : "text-white")
                }
              >
                [{sendbirdRegion}] {title}
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
            <tr>
            {headers && headers.length > 0 &&
              headers.map((header, index) =>
                  <th
                      key={"header_"+index}
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                              ? "bg-slate-50 text-slate-500 border-slate-100"
                              : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                  >
                    {header}
                  </th>)
            }
              </tr>
            </thead>
            <tbody>
            {dataList && dataList.map((data, r_index) =>
                <tr key={"row_" + r_index}>
                  {data.map((value, index) =>
                      <td
                          key={"data_"+index}  className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {value}
                      </td>
                  )}
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <button className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                      <i className="fas fa-heart"></i> Confirm
                    </button>
                  </td>
                </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

RequestListTable.defaultProps = {
  color: "light",
};

RequestListTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
