import React from "react";
import PropTypes from "prop-types";

// components
function buildData() {
  return [["1", "no1", "Daniel Lee", "Pending -> Approved", Date().toString()]];
}

export default function RequestHistory({ color, title, sendbirdRegion }: {color: string, title: string, sendbirdRegion: string}) {
  const headers = ["Request ID", "Region" , "Owner", "Status Change", "Changed At"];
  const dataList = buildData();
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-slate-700" : "text-white")
                }
              >
                [{sendbirdRegion}] {title}
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
            <tr>
            {headers && headers.length > 0 &&
              headers.map((header) =>
                  <th
                      key={header}
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                              ? "bg-slate-50 text-slate-500 border-slate-100"
                              : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                  >
                    {header}
                  </th>)
            }
              </tr>
            </thead>
            <tbody>
            {dataList && dataList.map((data, d_index) =>
                <tr key={"row_" + d_index} >
                  {data.map((value, index) =>
                      <td key={d_index + "_" + index}  className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {value}
                      </td>
                  )}
                </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

RequestHistory.defaultProps = {
  color: "dark",
};

RequestHistory.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
