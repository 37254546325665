import React from "react";
import RequestHistory from "../../../components/ScheduledAction/RequestHistory";
import {useParams} from "react-router-dom";

export default function History() {
    const {id}: { id: string } = useParams()
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full px-4">
                    <RequestHistory
                        sendbirdRegion={id}
                        color={"dark"}
                        title={"Request History"}
                    />
                </div>
            </div>
        </>
    );
}
