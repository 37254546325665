import React from "react";

export default function Login() {
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-10">
                  <h1 className="text-black text-3xl font-bold">
                    Welcome to Phoenix
                  </h1>
                </div>

                <div className="flex flex-wrap justify-center">
                  <div className="w-full sm:w-4/12 px-4">
                    <img src={require("../../assets/img/logo.png")} alt="..." className="max-w-full h-auto align-middle border-none" />
                  </div>
                </div>
                <hr className="mt-6 border-b-1 border-slate-300" />
              </div>
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="btn-wrapper text-center">
                  <button
                      className="bg-white active:bg-slate-50 text-slate-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-md ease-linear transition-all duration-150"
                      type="button"
                  >
                    <img
                        alt="okta login"
                        className="w-5 mr-1"
                        src={require("../../assets/img/okta.svg")}
                    />
                    Okta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
